/** @jsx jsx */
import { jsx, Link as TLink, Divider } from "theme-ui";
import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import LightHr from "../Util/LightHr";

class PostListing extends React.Component {
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach((postEdge) => {
      postList.push({
        path: `${postEdge.node.fields.slug}`,
        tags: postEdge.node.frontmatter.tags,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        short_desc: postEdge.node.frontmatter.short_desc,
        category: postEdge.node.frontmatter.category,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead,
        childImageSharp: postEdge.childImageSharp,
      });
    });
    return postList;
  }

  render() {
    const postList = this.getPostList();
    return (
      <div>
        {postList.map((post, i) => (
          <div sx={{ py: 2 }}>
            <TLink
              as={Link}
              sx={{
                fontWeight: 700,
                fontSize: [2, 3],
                color: `text`,
                display: "inline",
              }}
              to={post.path}
              key={post.title}
            >
              {post.title}
            </TLink>
            {/* <span>{post.category}</span> */}
            <br />

            <span>{post.short_desc}</span>
            {/* <span>{post.timeToRead && `${post.timeToRead} minute read`}</span> */}
            {post.childImageSharp && (
              <Img
                sx={{ width: "50%" }}
                fluid={post.childImageSharp.fluid}
                alt=""
              />
            )}
            {i !== postList.length - 1 && <LightHr />}
          </div>
        ))}
      </div>
    );
  }
}

export default PostListing;
