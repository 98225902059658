import { Link as TLink } from "theme-ui";
import { Link } from "gatsby";
import React from "react";

const CatLink = (name, linkTo, isCurrent) => (
  <TLink
    as={Link}
    to={linkTo}
    sx={{
      mx: 1,
      color: isCurrent ? "primary" : "secondary",
    }}
  >
    {name}
  </TLink>
);

export default CatLink;
