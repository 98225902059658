/** @jsx jsx */
import { jsx, Link as TLink, Button } from "theme-ui";

import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing/PostListing";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import ArraySplit from "../components/Util/ArraySplit";
import CatLink from "../components/Util/CatLink";

const _ = require("lodash");

class Listing extends React.Component {
  renderPaging() {
    const { pageContext } = this.props;
    const { currentPageNum, pageCount, basePath } = pageContext;
    const prevPage =
      currentPageNum - 1 === 1
        ? `/${basePath}/`
        : `/${basePath}/${currentPageNum - 1}/`;
    const nextPage = `/${basePath}/${currentPageNum + 1}/`;
    const isFirstPage = currentPageNum === 1;
    const isLastPage = currentPageNum === pageCount;
    const styles = {
      fontSize: [1, 2],
      color: "secondary",
      paddingRight: 2,
    };

    return (
      <div sx={{ paddingTop: "1em" }}>
        {!isFirstPage && (
          <TLink as={Link} to={prevPage} sx={styles}>
            Previous
          </TLink>
        )}
        {[...Array(pageCount)].map((_val, index) => {
          const pageNum = index + 1;
          return (
            <TLink
              as={Link}
              key={`listing-page-${pageNum}`}
              to={pageNum === 1 ? `/${basePath}/` : `/${basePath}/${pageNum}/`}
              sx={styles}
            >
              {pageNum}
            </TLink>
          );
        })}
        {!isLastPage && (
          <TLink as={Link} sx={styles} to={nextPage}>
            Next
          </TLink>
        )}
      </div>
    );
  }

  render() {
    const { data } = this.props;
    const { category, categorySet } = this.props.pageContext;
    const postEdges = data.allMarkdownRemark.edges;
    const imageMap = {};
    data.allFile.edges.forEach((edge) => {
      imageMap[edge.node.relativePath] = edge.node.childImageSharp;
    });
    const ammendedPostEdges = postEdges.map((edge) => ({
      ...edge,
      childImageSharp:
        imageMap[edge.node.frontmatter && edge.node.frontmatter.cover],
    }));

    const postCategories = [
      CatLink("all", "/posts/", typeof category === "undefined"),
    ];
    categorySet.forEach((cat) => {
      postCategories.push(
        CatLink(cat, `/posts/categories/${_.kebabCase(cat)}`, category === cat)
      );
    });

    const noPosts = <h3> No posts to show... check back soon :o </h3>;
    return (
      <Layout>
        <div className="listing-container">
          <div className="posts-container">
            <Helmet title={`Posts | ${config.siteTitle}`} />
            {/* <SEO /> */}
            <div sx={{ fontSize: 2 }}>
              <ArraySplit arr={postCategories} sep=" // " />
            </div>

            {ammendedPostEdges.length ? (
              <PostListing postEdges={ammendedPostEdges} />
            ) : (
              noPosts
            )}
          </div>
          {ammendedPostEdges.length ? this.renderPaging() : ""}
        </div>
      </Layout>
    );
  }
}

export default Listing;

/* eslint no-undef: "off" */
export const listingQuery = graphql`
  query ListingQuery(
    $skip: Int
    $limit: Int
    $imageList: [String]
    $filter: MarkdownRemarkFilterInput
    $sort: MarkdownRemarkSortInput
  ) {
    allFile(filter: { relativePath: { in: $imageList } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
          relativeDirectory
        }
      }
    }
    allMarkdownRemark(
      filter: $filter
      sort: $sort
      limit: $limit
      skip: $skip
    ) {
      distinct(field: frontmatter___category)
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            short_desc
            category
            tags
            cover
            date
          }
        }
      }
    }
  }
`;
